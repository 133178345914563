import React from 'react';

export default class Radio extends React.Component {
    constructor(props) {
        super(props);
    };

    render = () => {
        const { label, name, id, value, checked, onChange, disabled } = this.props;

        return (
            <label className="radio-input" htmlFor={id}>
                <input
                    className="radio-input__input"
                    type="radio"
                    id={id}
                    value={value}
                    checked={checked ? 'checked' : ''}
                    name={name}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                />
                <span className="radio-input__label">{label}</span>
            </label>
        );
    };
}
