import React, { Fragment } from 'react';
import ProductTypeResult from '../Search/ResultTypes/ProductTypeResult';

const RelatedProduct = ({ results }) => {
    const relatedProduct = results.map((item) => {
        return (
            <Fragment key={item._source.id}>
                <ProductTypeResult item={item._source} />
            </Fragment>
        );
    });

    return relatedProduct;
}

export default RelatedProduct;
