export default class ProductQuery {
    constructor(term, tax = null, count = 16, page = 1, sort = null, filters = null) {
        let query = {
            query: {
                function_score: {
                    query: {
                        bool: {
                            must: [
                                {
                                    match: {
                                        _index: 'pelcro_products',
                                    },
                                },
                                {
                                    multi_match: {
                                        query: term,
                                        type: 'cross_fields',
                                        operator: 'or',
                                        fields: [
                                            'name',
                                            'tags^5',
                                            'description',
                                            'caption',
                                            'image',
                                        ],
                                    },
                                },
                                {
                                    match: {
                                        active: true,
                                    },
                                },
                                {
                                    match: {
                                        hidden: false,
                                    },
                                },
                                {
                                    range: {
                                        publishDate: { lte: 'now' },
                                    },
                                },
                            ],
                            must_not: [],
                            filter: [],
                        },
                    },
                    functions: [
                        {
                            filter: {
                                range: {
                                    publishDate: { gte: 'now-1y', lte: 'now' },
                                },
                            },
                            weight: 5,
                        },
                        {
                            filter: {
                                range: {
                                    publishDate: { gte: 'now-2y', lt: 'now-1y' },
                                },
                            },
                            weight: 2,
                        },
                    ],
                    boost_mode: 'multiply',
                },
            },
            size: count,
            from: (page - 1) * count,
        };

        if (sort === null) {
            query.sort = {
                _score: 'desc',
            };
        }

        if (sort === 'price') {
            query.sort = {
                price: 'desc',
            };
        }

        if (sort === 'priceAsc') {
            query.sort = {
                price: 'asc',
            };
        }

        if (sort === 'dateDesc') {
            query.sort = {
                publishDate: 'desc',
            };
        }

        if (sort === 'dateAsc') {
            query.sort = {
                publishDate: 'asc',
            };
        }

        if (tax !== '' && tax !== null) {
            query.query.function_score.query.bool.must.push({
                match_phrase: {
                    categories: tax,
                },
            });
        }

        if (filters !== null) {
            if (filters.category !== undefined) {
                query.query.function_score.query.bool.must.push({
                    match_phrase: {
                        categories: filters.category,
                    },
                });
            }

            if (filters.status !== undefined) {
                switch (filters.status) {
                    case 'on-sale':
                        query.query.function_score.query.bool.must.push({
                            match: {
                                onSale: true,
                            },
                        });
                        break;
                    case 'limited-stock':
                        query.query.function_score.query.bool.must.push({
                            match: {
                                isLimitedStock: true,
                            },
                        });
                        break;
                }
            }

            if (filters.price !== undefined) {
                query.query.function_score.query.bool.must.push({
                    range: {
                        price: {
                            gte: filters.price[0],
                            lte: filters.price[1],
                        },
                    },
                });
            }

            if (filters.in_stock !== undefined) {
                query.query.function_score.query.bool.must.push({
                    match: {
                        inStock: true,
                    },
                });
            }

            if (filters.exclude_old_ids !== undefined) {
                query.query.function_score.query.bool.must_not.push({
                    bool: {
                        minimum_should_match: 1,
                        should: filters.exclude_old_ids.map(excludeOldId => ({
                            match: {
                                oldId: excludeOldId,
                            },
                        })),
                    },
                });
            }

            if (filters.exclude_ids !== undefined) {
                query.query.function_score.query.bool.must_not.push({
                    bool: {
                        minimum_should_match: 1,
                        should: filters.exclude_ids.map(excludeId => ({
                            match: {
                                id: excludeId,
                            },
                        })),
                    },
                });
            }

            if (filters.exclude_categories !== undefined) {
                query.query.function_score.query.bool.must_not.push({
                    match_phrase: {
                        categories: filters.exclude_categories,
                    },
                });
            }
        }

        return query;
    }
}
