import React from 'react';
import Image from '../Image';
import Radio from '../../Radio';
import SpinnerSVG from '../../../../svgs/spinner.svg';
import { isUserLoggedIn, getUserGeoData, getUserCurrency, formatPrice } from '../../../tools/utilities';

export default class ProductTypeResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            skus: props.item.skus ? props.item.skus : [],
            activeSkus: props.item.skus ? props.item.skus : [],
            selectedSku: props.item.skus ? props.item.skus[0] : props.item,
            tags: [
                {
                    key: 'outofstock',
                    value: 'Out of stock',
                },
                {
                    key: 'on-sale',
                    value: 'Sale',
                },
                {
                    key: 'signed',
                    value: 'Signed',
                },
                {
                    key: 'exclusive',
                    value: 'Exclusive',
                },
                {
                    key: 'popular',
                    value: 'Popular',
                },
                {
                    key: 'personalised',
                    value: 'Personalised',
                },
            ],
            descriptionActive: false,
            activeTag: '',
            country: 'GB',
            currency: 'GBP',
            locale: 'locale' in Intl.NumberFormat().resolvedOptions()
                ? Intl.NumberFormat().resolvedOptions().locale
                : 'en-GB',
            userGeoData: {
                country: 'GB',
                currency: 'GBP',
                locale: 'en-GB',
            },
        };
    }

    async componentDidMount() {
        await this.setUserGeoData();
        const { userGeoData } = this.state;

        document.addEventListener('PelcroUserLoaded', async () => {
            let userCurrency = getUserCurrency();
            if (userCurrency === '') {
                userCurrency = userGeoData.currency;
            }

            this.setCurrency(userCurrency);
            this.setCountry(userGeoData.country);
            this.setupSkus();
        });

        if (!isUserLoggedIn()) {
            this.setCurrency(userGeoData.currency);
            this.setCountry(userGeoData.country);
            this.setupSkus();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item !== null && prevProps !== this.props) {
            this.setupSkus(false);
        }
    }

    setupSkus = (dispatch = true) => {
        this.setSkus();
        this.setActiveTag();
        this.setState({ loading: false });
        if (dispatch) {
            document.dispatchEvent(new Event('PelcroProductsLoaded'));
        }
    };

    setUserGeoData = async () => {
        const userGeoData = await getUserGeoData();
        this.setState({ userGeoData });
    };

    setCountry = (country) => {
        if (country === '') {
            country = 'GB';
        }
        this.setState({ country: country });
    };

    setCurrency = (currency) => {
        if (currency === '') {
            currency = 'GBP';
        }
        this.setState({ currency: currency });
    };

    setActiveTag = () => {
        const { item } = this.props;
        const { activeSkus, tags } = this.state;
        let activeTag = '';

        if (!item.inStock || activeSkus.length <= 0) {
            activeTag = 'outofstock';
        } else if (!item.onSale) {
            activeTag = 'on-sale';
        } else {
            for (let i = 0; i < item.tags.length; i++) {
                const activeTagItem = tags.find(tag => tag.key == item.tags[i].toLowerCase());

                if (activeTagItem) {
                    activeTag = activeTagItem.key;
                    break;
                }
            }
        }

        this.setState({ activeTag });
    };

    setSkus = () => {
        const { item } = this.props;
        const { country, currency } = this.state;
        let skus = item.skus;

        // We need to also match the users currency as can't place orders in different currencies but need to exclude ROW
        if (isUserLoggedIn()) {
            // GBP is both UK and ROW prices we need to check the user country
            if (currency === 'GBP') {
                if (country === 'GB') {
                    skus = item.skus.filter(sku => sku.currency.toUpperCase() === 'GBP' && sku.countriesAvailable.length > 0 && sku.countriesAvailable.includes('GB'));
                } else {
                    skus = item.skus.filter(sku => sku.currency.toUpperCase() === 'GBP' && sku.name.toLowerCase().includes('row'));
                }
            } else {
                skus = item.skus.filter(sku => sku.currency.toUpperCase() === currency);
            }
        } else {
            skus = item.skus.filter(sku => sku.countriesAvailable.length > 0 && sku.countriesAvailable.includes(country) && !sku.name.toLowerCase().includes('row'));

            // If there are no SKUs in the users country then will need to display SKUs with no country
            if (skus.length <= 0) {
                skus = item.skus.filter(sku => sku.name.toLowerCase().includes('row'));
            }
        }

        // Sort SKu's so print is first
        skus.sort((a, b) => a.name.localeCompare(b.name)).reverse();

        const activeSkus = skus.filter(sku => sku.inStock);

        let selectedSku = item;
        if (activeSkus.length > 0) {
            const firstKey = Object.keys(activeSkus)[0];
            selectedSku = activeSkus[firstKey];
        }

        this.setState({ skus, activeSkus, selectedSku });
    };

    showTag() {
        const { tags, activeTag } = this.state;
        const activeTagItem = tags.find(tag => tag.key == activeTag);

        if (activeTagItem) {
            return (
                <span className={`product-card__tag product-card__tag--${activeTagItem.key} proxima`}>
                    {activeTagItem.value}
                </span>
            );
        }

        return <></>;
    }

    showPrice() {
        const { selectedSku, locale } = this.state;

        if (selectedSku.price !== selectedSku.regularPrice) {
            return (
                <span className="product-card__pricing proxima">
                    <span className="amount amount--sale-price">
                        {formatPrice(selectedSku.price, selectedSku.currency, locale)}
                    </span>
                    <span className="amount amount--old-price">
                        {formatPrice(selectedSku.regularPrice, selectedSku.currency, locale)}
                    </span>
                </span>
            );
        }

        return (
            <span className="product-card__pricing proxima">
                <span className="amount">
                    {formatPrice(selectedSku.price, selectedSku.currency, locale)}
                </span>
            </span>
        );
    }

    showSkus() {
        const { item } = this.props;
        const { skus, selectedSku } = this.state;

        if (skus && skus.length > 1) {
            return (
                <div className="product-card__skus radio-input-group">
                    {skus.map((sku, i) => {
                        let label = sku.description !== '' ? sku.description : sku.name;
                        if (label.toLowerCase().includes('print')) {
                            label = 'Print';
                        } else if (label.toLowerCase().includes('digital')) {
                            label = 'Digital';
                        }
                        return <div className={'radio-input-group__radio' + (selectedSku.id === sku.id ? ' radio-input-group__radio--active' : '')} key={i}>
                            <Radio
                                name={`product-card-radio-${item.id}`}
                                id={`product-card-radio-${item.id}-${sku.id}`}
                                value={sku.id}
                                label={label}
                                checked={selectedSku.id === sku.id}
                                onChange={this.handleSkuChange}
                                disabled={!sku.inStock}
                            />
                        </div>
                    })}
                </div>
            );
        }

        return <></>;
    }

    handleSkuChange = e => {
        if (e.target !== null) {
            const { skus } = this.state;
            const skuItem = skus.find(sku => sku.id == e.target.value);
            if (skuItem) {
                this.setState({
                    selectedSku: skuItem,
                });
            }
        }
    };

    handleAddToCartUrl = () => {
        const { selectedSku } = this.state;
        const url = new URL(window.location);
        url.searchParams.set(
            'view',
            'cart'
        );
        url.searchParams.set(
            'sku_id',
            `${selectedSku.id}`
        );

        return url.href;
    };

    toggleDescription = () => {
        const { descriptionActive } = this.state;
        this.setState({
            descriptionActive: descriptionActive ? false : true,
        });
    };

    render = () => {
        const { item, modifier } = this.props;
        const { loading, activeSkus, selectedSku, descriptionActive } = this.state;
        const analytics = this.props.analytics || '';
        const hideContent = this.props.hideContent || false;
        const displayBuyLink = this.props.displayBuyLink || false;
        const productTitle = item.caption !== '' ? item.caption : item.name;
        const modifierClass = modifier && modifier !== '' ? ` product-card--${modifier}` : '';
        const descriptionClass = descriptionActive ? ` product-card__description--active` : '';

        return (
            <article className={`product-card${modifierClass}`}>
                <div className="product-card__content">
                    {!hideContent && this.showTag()}
                    {!hideContent && item.image &&
                        <div className="product-card__image">
                            <Image uri={item.image} />
                        </div>
                    }
                    {!hideContent && <h3 className="product-card__title sanomat">{productTitle}</h3>}
                    {!hideContent && item.description !== '' &&
                        <div className={`product-card__description ${descriptionClass}`}>
                            {descriptionActive ? item.description : item.description.split(' ').splice(0, 14).join(' ')}
                            {!descriptionActive &&  item.description.split(' ').length > 14 && <button
                                className="product-card__description-toggle"
                                onClick={() => this.toggleDescription()}>
                                Read more
                            </button>}
                        </div>
                    }
                </div>
                <div className="product-card__actions">
                    {loading && <img
                        src={SpinnerSVG}
                        alt="loading"
                        className="loading"
                        style={{
                            width: '20px',
                            height: '20px',
                            marginTop: '3rem',
                            marginBottom: '3rem',
                        }}
                    />}
                    {!loading && item.inStock && activeSkus.length > 0 && this.showSkus()}
                    {!loading && displayBuyLink ? item.inStock && activeSkus.length > 0 && (
                        <a
                            className={`product-card__purchase button button--alt ${analytics}`}
                            href={this.handleAddToCartUrl()}>
                            Add to cart
                            {this.showPrice()}
                        </a>
                    ) : !loading && item.inStock && activeSkus.length > 0 && (
                        <button
                            className={`pelcro-add-to-cart-button product-card__purchase button button--alt ${analytics}`}
                            data-sku-id={selectedSku.id}>
                            Add to cart
                            {this.showPrice()}
                        </button>
                    ) }
                </div>
            </article>
        );
    };
}
