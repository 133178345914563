import '../images';
import '../svgs';

import './modules';
import './components';
import { FontLoader } from './tools';
import { renderPaywall, renderAds } from './tools/utilities';
import RemoveFocusState from 'remove-focus-state';

renderPaywall();
renderAds();

const fonts = new FontLoader([
    { name: 'Proxima', weight: 400 },
    { name: 'Sanomat', weight: 700 },
    { name: 'Sanomat', weight: 400 },
    { name: 'Publico', weight: 400 }
]);

fonts.load();

new RemoveFocusState();

document.getElementsByClassName('no-js')[0].classList.remove('no-js');

// fix for gift card date formats
$(function() {
    function setDateFormat() {
        $(this).datepicker("option", "dateFormat", "d MM yy");
        $(this).off('focus', setDateFormat);
    }

    $('.woocommerce_gc_giftcard_form .datepicker').on('focus', setDateFormat);
});

// Temp to remove legacy user data
localStorage.removeItem('userDetails');
localStorage.removeItem('viewedPosts');
localStorage.removeItem('disable_ads');
