const throttle = require('lodash/throttle');

let lastScrollTop = 0;
let headerElement = $('.site-header');
let windowTop = headerElement.offset().top > 0 ? headerElement.offset().top * 2 : 100;
const subMenuHeight = headerElement.find('.main-navigation .sub-navigation:first');
let sidebarOffset = subMenuHeight.length
    ? headerElement.outerHeight() + subMenuHeight.outerHeight()
    : headerElement.outerHeight();

// Update site-header to have a fixed height to stop the bounce issue
$(window).on('load resize triggerSlotsRendered', function() {
    sidebarOffset = subMenuHeight.length
        ? headerElement.outerHeight() + subMenuHeight.outerHeight()
        : headerElement.outerHeight();
    headerElement.css('height', 'auto');
    headerElement.css('height', headerElement.outerHeight());

    document.documentElement.style.setProperty('--sidebar-offset', `${sidebarOffset}px`);
});

// Update header position if ads rendered
$(window).on('triggerSlotsRendered', function() {
    windowTop = headerElement.offset().top > 0 ? headerElement.offset().top * 2 : 100;
});

$(window).on(
    'scroll resize',
    throttle(function() {
        if (
            $('.search-open').length >= 1 ||
            $('.site-header--no-scroll').length >= 1 ||
            $('.menu-select--open').length >= 1
        )
            return;

        let windowPosition = window.scrollY;

        if (windowPosition >= windowTop) {
            headerElement.addClass('site-header--fixed');

            let newOffset =
                headerElement.find('.header-bar').outerHeight() + headerElement.find('.main-navigation').outerHeight();
            document.documentElement.style.setProperty('--sidebar-offset', `${newOffset}px`);
        } else {
            headerElement.removeClass('site-header--fixed');
            document.documentElement.style.setProperty('--sidebar-offset', `${sidebarOffset}px`);
        }
    }, 400),
);

$('[data-behaviour="navigation-toggle"]').on('click', function(e) {
    e.preventDefault();

    headerElement.toggleClass('site-header--open');
    $('body').toggleClass('u-lock-scrolling');
});

// For search Toggle in mobile
$('[data-behaviour="search-toggle"]').on('click', function(e) {
    e.preventDefault();

    headerElement.toggleClass('serach-form--open');
});

$('html').on('click', function(e) {
    if (
        !$(e.target).closest('.site-header *').length &&
        $('.site-header').hasClass('site-header--open') &&
        $('.mobile-navigation').is(':visible')
    ) {
        $('.site-header').removeClass('site-header--open');
        $('body').removeClass('u-lock-scrolling');
    }
});

$('.sub-navigation .menu-item').on('click', 'a, select', function(e) {
    e.stopPropagation();
});

$('.mobile-navigation .menu-item-has-children').on('click', 'a', function(e) {
    e.preventDefault();

    // Close any children sub-menus
    if (
        $(this)
            .parent('.menu-item-has-children')
            .hasClass('open')
    ) {
        $(this)
            .parent('.menu-item-has-children')
            .find('.open')
            .removeClass('open');
    }

    $(this)
        .parent('.menu-item-has-children')
        .toggleClass('open');

    if (
        $(this)
            .parents('.mobile-menu')
            .find('.open').length < 1
    ) {
        $(this)
            .parents('.mobile-menu')
            .removeClass('open');
    } else {
        $(this)
            .parents('.mobile-menu')
            .addClass('open');
    }

    // Add depth class to menu
    let depth = $(this).data('depth') ? $(this).data('depth') : 0;
    if (depth === 0) {
        $(this)
            .parents('.mobile-menu')
            .removeClass('open--1 open--2 open--3');
    } else if (depth === 1) {
        $(this)
            .parents('.mobile-menu')
            .removeClass('open--2 open--3');
    } else if (depth === 2) {
        $(this)
            .parents('.mobile-menu')
            .removeClass('open--3');
    }

    $(this)
        .parents('.mobile-menu')
        .toggleClass('open--' + depth);
});

$('.main-navigation .menu-select').on('click', 'a', function(e) {
    e.preventDefault();

    // Remove active state on other menus
    $('.menu-select.active')
        .not($(this).parent('.menu-select'))
        .each(function() {
            $(this).removeClass('active');
        });

    $(this)
        .parent('.menu-select')
        .toggleClass('active');

    if ($('.menu-select.active').length >= 1) {
        $('body').addClass('menu-select--open');
        $('html, body').scrollTop(0);
    } else {
        $('body').toggleClass('menu-select--open');
    }
});

$('html').on('click', function(e) {
    if (
        !$(e.target).closest('.main-navigation .menu-select').length &&
        $('.menu-select').hasClass('active') &&
        $('.main-navigation').is(':visible')
    ) {
        $('.menu-select').removeClass('active');
        $('body').removeClass('menu-select--open');
    }
});

$(window).on('toggleMobileScroll', function() {
    $('body').toggleClass('u-lock-scrolling-mobile');
});

function stickyHeader() {
    let windowPosition = window.scrollY;
    let windowTarget = $(window).height() / 6;

    // If user is scrolling up
    if (windowPosition < lastScrollTop) {
        headerElement.removeClass('site-header--fixed');
        lastScrollTop = windowPosition + 64;
        return;
    }

    if (windowPosition >= windowTarget) {
        headerElement.addClass('site-header--fixed');
    } else {
        headerElement.removeClass('site-header--fixed');
    }

    lastScrollTop = windowPosition;
}

$('.main-navigation .menu-item.depth--0').each(function() {
    const hoverParentIndex = $(this).index();
    $(this).attr('data-parent-index', hoverParentIndex);
});

$('.main-navigation .menu-item.depth--1').each(function() {
    const hoverIndex = $(this).index();
    $(this).attr('data-sub-index', hoverIndex);
});

$(document).on('mouseenter', '.main-navigation .menu-item.depth--0', function() {
    const hoverParentIndex = $(this).attr('data-parent-index');

    $('#menu-main-navigation').attr('data-parent-index', hoverParentIndex);
    $('.main-navigation li').removeClass('sub-navigation-active');
    $(`.main-navigation li[data-parent-index="${hoverParentIndex}"]`).addClass('sub-navigation-active');
});

$(document).on('mouseenter', '.main-navigation .menu-item.depth--0 .menu-item.depth--1', function() {
    const getcurrentParentIndex = $('#menu-main-navigation').attr('data-parent-index');
    const getcurrentSubIndex = $('#menu-main-navigation').attr('data-sub-index');
    const hoverParentIndex = $(this)
        .parent('.sub-navigation')
        .parent('.menu-item')
        .index();
    const hoverSubIndex = $(this).attr('data-sub-index');

    if (getcurrentParentIndex !== hoverParentIndex && getcurrentSubIndex !== hoverSubIndex) {
        $('#menu-main-navigation').attr('data-sub-index', hoverSubIndex);
        $('.main-navigation li .sub-navigation li .sub-menu').removeClass('sub-menu-active');
        $(
            `.main-navigation li[data-parent-index="${hoverParentIndex}"] .sub-navigation li[data-sub-index="${hoverSubIndex}"] .sub-menu`,
        ).addClass('sub-menu-active');
    }
});

$(document).on('mouseleave', '.main-navigation .menu-item.depth--0 .menu-item.depth--1', function() {
    if ($(this).hasClass('menu-item-has-children')) {
        const hoverParentIndex = $(this)
            .parent('.sub-navigation')
            .parent('.menu-item')
            .index();
        const hoverSubIndex = $(this).attr('data-sub-index');

        if (hoverSubIndex) {
            $('#menu-main-navigation').removeAttr('data-parent-index');
            $('#menu-main-navigation').removeAttr('data-sub-index');
            $(
                `.main-navigation li[data-parent-index="${hoverParentIndex}"] .sub-navigation li[data-sub-index="${hoverSubIndex}"] .sub-menu`,
            ).removeClass('sub-menu-active');
        }
    }
});

const headerBasketButton = document.querySelector('[data-target="header-basket-button"]');
if (headerBasketButton !== null) {
    document.addEventListener('PelcroElementsCartItemAdded', () => {
        headerBasketButton.click();
    });
}
