import React, { Suspense } from 'react';
import ReactRender from '../tools/react-render';

import './TagAutomation';
import './Product';

const databaseTable = document.querySelector('[data-behaviour="react-database-table"]');
if (databaseTable) {
    const DatabaseList = React.lazy(() => import('./Database/DatabaseList'));
    const databaseType = databaseTable.getAttribute('data-database');

    const DatabaseTableApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <DatabaseList type={databaseType} />
        </Suspense>
    );

    ReactRender(<DatabaseTableApp />, databaseTable);
}

const reactSearch = document.querySelector('[data-behaviour="react-adv-search"]');
if (reactSearch) {
    const SearchPage = React.lazy(() => import('./Search/Page/SearchPage'));
    const SearchPageApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <SearchPage />
        </Suspense>
    );

    ReactRender(<SearchPageApp />, document.querySelector('[data-behaviour="react-adv-search"]'));
}

if (document.querySelector('[data-behaviour="react-search-overlay"]')) {
    const SearchOverlay = React.lazy(() => import('./Search/Overlay/SearchOverlay'));

    const SearchOverlayApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <SearchOverlay />
        </Suspense>
    );

    ReactRender(<SearchOverlayApp />, document.querySelector('[data-behaviour="react-search-overlay"]'));
}

const puzzlesEl = document.querySelector('[data-behaviour="react-puzzles"]');
if (puzzlesEl) {
    const Puzzles = React.lazy(() => import('./Puzzles'));
    const PuzzlesApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <Puzzles />
        </Suspense>
    );

    ReactRender(<PuzzlesApp />, puzzlesEl);
}

const issueCarouselEl = document.querySelector('[data-behaviour="issue-carousel"]');
if (issueCarouselEl) {
    const IssueCarousel = React.lazy(() => import('./IssueCarousel'));
    const pages = issueCarouselEl.getAttribute('data-pages');
    const issue = issueCarouselEl.getAttribute('data-issue');
    const count = issueCarouselEl.getAttribute('data-count');
    const start = issueCarouselEl.getAttribute('data-start-index');
    const path = issueCarouselEl.getAttribute('data-path');
    const paywall = JSON.parse(issueCarouselEl.getAttribute('data-paywall'));

    const IssueCarouselApp = () => (
        <Suspense fallback={<div className="issue-slider issue-slider--loading">
        <div className="issue-slider__list">
          {Array.from(
            { length: 6 },
            (_, i) => <div key={i} className="issue-slider__item"><div className="issue-slider__page"></div></div>
          )}
        </div>
      </div>}>
            <IssueCarousel pages={pages} issue={issue} count={count} start={start} paywall={paywall} path={path} />
        </Suspense>
    );

    ReactRender(<IssueCarouselApp />, issueCarouselEl);
}

const circuitsEl = document.querySelectorAll('[data-behaviour="react-circuits"]');
if (circuitsEl) {
    const Circuits = React.lazy(() => import('./Database/Circuits'));
    
    circuitsEl.forEach(circuit => {
        const circuitData = circuit.getAttribute('data-circuits');

        const CircuitsApp = () => (
            <Suspense fallback={<div></div>}>
                <Circuits data={circuitData} />
            </Suspense>
        );
    
        ReactRender(<CircuitsApp />, circuit);
    });
}
   
const imageLightboxEls = document.querySelectorAll('[data-behaviour="image-lightbox"]');
if (imageLightboxEls.length > 0) {
    imageLightboxEls.forEach(imageLightboxEl => {
        const ImageLightbox = React.lazy(() => import('./ImageLightbox'));
        const thumb = imageLightboxEl.getAttribute('data-thumb');
        const full = imageLightboxEl.getAttribute('data-full');
        const title = imageLightboxEl.getAttribute('data-title');

        const ImageLightboxApp = () => (
            <Suspense fallback={<div>Loading...</div>}>
                <ImageLightbox thumb={thumb} full={full} title={title} />
            </Suspense>
        );

        ReactRender(<ImageLightboxApp />, imageLightboxEl);
    });
}
