const throttle = require('lodash/throttle');

$('[data-behaviour="toggle-comparisons"]').on('click', function(e) {
    e.preventDefault();

    $(this)
        .parents('.subscription-comparison')
        .find('.subscription-comparison__grid')
        .toggleClass('subscription-comparison__grid--open');
    $(this).toggleClass('button--chevron-down button--chevron-up');
});

function resizeComparisonColumns(resize) {
    if (resize) {
        $('.features__list [data-behaviour="subscription-comparison-item"]').each(function() {
            var $this = $(this);
            var index = $this.data('index');
            var $siblings = $('[data-behaviour="subscription-comparison-item"][data-index="' + index + '"]').not(this);
            var height = Math.floor(parseInt($this.outerHeight())) + 2 + 'px';
            $siblings.outerHeight(height);
        });
    } else {
        $('[data-behaviour="subscription-comparison-item"]').height('auto');
    }
}

if ($('[data-behaviour="subscription-comparison-item"]').length > 0) {
    $(window).on(
        'load resize',
        throttle(function() {
            var width = $(window).width();
            // Only fire when columns and not already set
            if (width > 1024) {
                resizeComparisonColumns(true);
            } else {
                resizeComparisonColumns(false);
            }
        }, 250),
    );
}
