document.addEventListener("om.Form.validate", function (event) {
  // Make sure the custom code only runs for a specific campaign
  var form = event.detail.Form;

  // Look for the name input
  var nameInputFilter = form.inputs.filter(function (el) {
    return el.getAttribute("data-fieldid") === "nameInput";
  });

  // Check if name input could be found
  var nameInput = nameInputFilter.length ? nameInputFilter[0] : null;

  if (nameInput) {
    var nameParts = nameInput.value.trim().split(" ");

    // Here is where you would validate the name field
    if (nameInput.value && nameParts.length < 2) {
      form.errors.validate.push("Please, enter your full name.");
    }
  }
});
