import Query from './Query';
import ProductQuery from './ProductQuery';
import DatabaseQuery from './Database/Query';

export default class Search {
    constructor(term, tax, type, postPerPage, page, filters = null, sort = null) {
        if (term === null) {
            this.query = null;

            return;
        }

        let header = JSON.stringify({
            index: 'web_search',
        });

        let productHeader = JSON.stringify({
            index: 'pelcro_products',
        });

        let bulkQuery = '';

        // Get the actual results for the query
        if (type.includes('product')) {
            bulkQuery += productHeader + '\n';
            bulkQuery += JSON.stringify(new ProductQuery(term, tax, postPerPage, page, sort, filters));
        } else {
            bulkQuery += header + '\n';
            bulkQuery +=
                type === 'database'
                    ? JSON.stringify(new DatabaseQuery(term, null, postPerPage, page, sort, filters))
                    : JSON.stringify(new Query(term, tax, type, postPerPage, page, sort, filters));
        }
        bulkQuery += '\n';

        // Get product tab count
        bulkQuery += productHeader + '\n';
        bulkQuery += JSON.stringify(new ProductQuery(term, null, 0, 1, sort)) + '\n';

        // Get article tab count
        bulkQuery += header + '\n';
        bulkQuery += JSON.stringify(new Query(term, null, ['post'], 0, 1, sort)) + '\n';

        // Get video tab count
        bulkQuery += header + '\n';
        bulkQuery += JSON.stringify(new Query(term, 'video', ['post'], 0, 1, sort)) + '\n';

        // Get podcast tab count
        bulkQuery += header + '\n';
        bulkQuery += JSON.stringify(new Query(term, 'podcast', ['post'], 0, 1, sort)) + '\n';

        // Get archive tab count
        bulkQuery += header + '\n';
        bulkQuery += JSON.stringify(new Query(term, null, ['issue_content'], 0, 1, sort)) + '\n';

        // Get Database tab count
        bulkQuery += header + '\n';
        bulkQuery += JSON.stringify(new DatabaseQuery(term, null, 0, 1, sort)) + '\n';

        if (type.includes('product')) {
            // Get max product price
            bulkQuery += productHeader + '\n';
            bulkQuery += JSON.stringify(new ProductQuery(term, null, 1, 1, 'price', filters)) + '\n';
        }

        bulkQuery += '\n';

        this.query = bulkQuery;
    }

    async getResults() {
        let headers = new Headers();

        headers.append('Content-Type', 'application/x-ndjson');

        const esAuth = process.env.ES_AUTH;

        if (esAuth) {
            headers.append('Authorization', `Basic ${esAuth}`);
        }

        return await fetch(`${process.env.ES_ENDPOINT}/_msearch/`, {
            method: 'POST',
            headers: headers,
            body: this.query,
        }).then(res => res.json());
    }
}
