import React, { Suspense } from 'react';
import ReactRender from '../../tools/react-render';

const productFiltersEl = document.querySelector('[data-behaviour~="react-product-filters"]');
if (productFiltersEl) {
    const ProductFilters = React.lazy(() => import('./ProductFilters'));

    const filtersData = JSON.parse(productFiltersEl.getAttribute('data-react-json'));
    const category = productFiltersEl.getAttribute('data-category-name');
    const sort = productFiltersEl.getAttribute('data-sort');
    const sales = productFiltersEl.getAttribute('data-sales');
    const featured = productFiltersEl.getAttribute('data-featured');
    const ProductFiltersApp = () => (
        <Suspense fallback={<div>Loading...</div>}>
            <ProductFilters
                filters={filtersData}
                category={category}
                sort={sort}
                sales={sales}
                featured={featured}
            />
        </Suspense>
    );

    ReactRender(<ProductFiltersApp />, productFiltersEl);
}

const productListEls = document.querySelectorAll('[data-behaviour~="react-product-list"]');
if (productListEls.length > 0) {
    productListEls.forEach(productListEl => {
        const category = productListEl.getAttribute('data-category-name');
        const perPage = productListEl.getAttribute('data-per-page');
        const sort = productListEl.getAttribute('data-sort');
        const featured = productListEl.getAttribute('data-featured');
        const sale = productListEl.getAttribute('data-sale');
        const includeOldIds = productListEl.getAttribute('data-include-old-ids') !== ''
            ? JSON.parse(productListEl.getAttribute('data-include-old-ids'))
            : '';
        const excludeOldIds = productListEl.getAttribute('data-exclude-old-ids') !== ''
            ? JSON.parse(productListEl.getAttribute('data-exclude-old-ids'))
            : '';
        const includeIds = productListEl.getAttribute('data-include-ids') !== ''
            ? JSON.parse(productListEl.getAttribute('data-include-ids'))
            : '';
        const excludeIds = productListEl.getAttribute('data-exclude-ids') !== ''
            ? JSON.parse(productListEl.getAttribute('data-exclude-ids'))
            : '';
        const analytics = productListEl.getAttribute('data-analytics');
        const modifier = productListEl.getAttribute('data-modifier');
        const hideContent = productListEl.getAttribute('data-hide-content');
        if (category !== '' || includeIds !== '') {
            const ProductList = React.lazy(() => import('./ProductList'));

            const ProductListApp = () => (
                <Suspense fallback={<div>Loading...</div>}>
                    <ProductList
                        category={category}
                        perPage={perPage}
                        sort={sort}
                        featured={featured}
                        sale={sale}
                        includeOldIds={includeOldIds}
                        excludeOldIds={excludeOldIds}
                        includeIds={includeIds}
                        excludeIds={excludeIds}
                        analytics={analytics}
                        modifier={modifier}
                        hideContent={hideContent}
                    />
                </Suspense>
            );
    
            ReactRender(<ProductListApp />, productListEl);
        }
    });
}
