import Query from '../Search/Query';
import ProductQuery from '../Search/ProductQuery';
import BaseSearch from '../Search/Search';

export default class Search extends BaseSearch {
    constructor(term, tax, type, postPerPage, filters = null, sort = null) {
        super(null);

        const header = JSON.stringify({
            index: type === 'product' ? 'pelcro_products' : 'web_search',
        });
        const searchQuery = type === 'product'
            ? new ProductQuery(term, tax, postPerPage, 1, sort, filters)
            : new Query(term, tax, type, postPerPage, 1, sort, filters);

        let bulkQuery = header + '\n';
        bulkQuery += JSON.stringify(searchQuery) + '\n';

        this.query = bulkQuery;
    }
}
